/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { lazy, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import SetDestination from './SetDestination';
import SetProjuctDestination from './setProductDestination';
import Loader from './Loader';
import SetLocation from './showlocation';

let projectData
const localprojectData = localStorage.getItem('project_data');
if (localprojectData) {
    projectData = JSON.parse(localprojectData);
}

const CustomTagFilter = ({
    from, type,
    tags, close,
    loading, setCurrentFloor,
    setFromDestinationData,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResult, setFilteredResult] = useState([]);
    const [openLocation, setOpenLocation] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const [openOtherPins, setOpenOtherPins] = useState(false);
    const [otherPinType, setOtherPinType] = useState();

    const [types, setType] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        setFilteredResult(tags)
    }, [tags])

    const selectedFromPoint = (tag) => {
        if (tag.type == '1') {
            setOpenLocation(true);
            setType(from);
            setId(tag?.enc_id);
        } else if (tag.type == '2') {
            setOpenProduct(true);
            setType(from);
            setId(tag?.enc_id);
        } else {
            if (tag?.type == 6) {
                let VTdata = {
                    from: tag?.name,
                    from_id: tag?.enc_id,
                    from_floor_plan: tag?.floor_plan,
                    type: tag?.type,
                    from_floor_plan_id: tag?.enc_floor_plan_id,
                    from_type_name: 'vertical',
                    from_draft_id: tag?.draft_id
                }
                localStorage.setItem('vertical', JSON.stringify(VTdata));
            }
            setOpenOtherPins(true)
            setType(from);
            setId(tag?.enc_id);
            setOtherPinType(tag?.type)
        }
    }

    return (
        <>
            <Row justify="center" className='mt-3'>
                <>
                    {filteredResult?.map((tag) => (
                        <Col sm={12} key={tag?.enc_id}>
                            <div
                                className="border-div"
                                onClick={() => selectedFromPoint(tag)}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        selectedFromPoint(tag);
                                    }
                                }}
                                role="button"
                                tabIndex={0}
                            >
                                <h6 className='listHeading mb-0'>{tag?.name}</h6>
                                {tag?.product_code &&
                                    <p className='mb-0 number'>{tag?.product_code}</p>
                                }
                                <p className="mb-0 listcontent">{tag?.floor_plan}</p>
                            </div>
                        </Col>
                    ))}
                    {(loading) && (
                        <div className='loader-pos'>
                            <Loader />
                        </div>
                    )}
                </>
            </Row >
            <SetDestination open={openLocation} setOpen={setOpenLocation} type={types} id={id} closes={close} />
            <SetProjuctDestination open={openProduct} setOpen={setOpenProduct} type={types} id={id} closes={close} />
            <SetLocation
                open={openOtherPins}
                setOpen={setOpenOtherPins}
                id={id}
                type={otherPinType}
                destination={types}
                closes={close}
                setCurrentFloor={setCurrentFloor}
                setFromDestinationData={setFromDestinationData}
            />
        </>
    );
}

export default CustomTagFilter;
