import React, { Fragment, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes, // Add Routes component
  Navigate, // Add Navigate component
} from 'react-router-dom';
import ViewFloorPLan from '../pages/firstFloorPage';
import ShowDestination from '../pages/ShowDestination';
import StartingPoint from '../pages/StartingPoint';
import Destination from '../pages/Destination';
import SetProjuctDestination from '../pages/setProductDestination';
import SetDestination from '../pages/SetDestination';
import ReportIssue from '../pages/ReportIssue';
import ReportIssueForm from '../pages/ReportIssueForm';
import ShowLocation from '../pages/showlocation';
import CustomTagFilter from '../pages/CustomTagFilter';
import NotFound from '../pages/notFound';


const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Fragment>
        <Route path="/" element={<ViewFloorPLan />} />
        <Route path="/view-floorplan/:id" element={<ViewFloorPLan />} />
        <Route path="/show-destination/:type" element={<ShowDestination />} />
        {/* <Route path="/starting-point" element={<StartingPoint />} /> */}
        {/* <Route path="/destination-point" element={<Destination />} /> */}
        <Route path="/set-product-destination/:id/:type" element={<SetProjuctDestination />} />
        {/* <Route path="/set-destination/:id/:type" element={<SetDestination />} /> */}
        <Route path="/report-issue/:customer_id" element={<ReportIssue />} />
        <Route path="/report-an-issue/:customer_id" element={<ReportIssueForm />} />
        <Route path="/show-location/:id/:type/:destination" element={<ShowLocation />} />
        <Route path="/custom-tags-filter/:id/:type" element={<CustomTagFilter />} />
        <Route path="/not-found/:id" element={<NotFound />} />

      </Fragment>
    )
  );

  return (
    <Suspense >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default AppRoutes;
