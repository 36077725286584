/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { CardBody, Card, Row, Col, Button } from 'reactstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../hooks/axiosClient';
import './page.css';
import { environmentaldatas } from '../constant/defaultValues';
import Sheet from 'react-modal-sheet';

const { image_url } = environmentaldatas;

const SetProjuctDestination = ({
    open, setOpen, id, type, closes,
    setCurrentFloor,
    setFromDestinationData
}) => {
    const navigate = useNavigate();
    const close = () => {
        setOpen(false);
        setProductDtls([])
    }
    const [productDtls, setProductDtls] = useState([]);
    const project = localStorage.getItem("project_data");
    const dataProject = project ? JSON.parse(project) : null;

    const getProductDetails = async (id) => {
        try {
            const response = await getRequest(`destinations-info/${id}/2`);
            const data = response.data.data ?? [];
            data.specifications = JSON.parse(data.specifications)
            let images = data?.image_path ? JSON.parse(data?.image_path) : []
            console.log(images, 'images')
            let values = {
                ...data,
                image_path: images?.map(item => (image_url + item))
            }
            let items = {
                from: data?.name,
                from_id: data?.enc_id,
                from_floor_plan: data?.floor_plan,
                project_id: data?.enc_project_id,
                from_floor_plan_id: data?.enc_floor_plan_id,
                customer_id: data?.enc_customer_id,
                from_draft_id: data?.draft_id,
                type: data?.type
            };
            if (setCurrentFloor && type == 'from') {
                // setCurrentFloor(items?.enc_floor_plan_id)
                // setFromDestinationData(items)
            }
            setProductDtls(values);
            getPinViews(values?.enc_id);
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    const getPinViews = async (publishedId) => {
        const formData = {
            customer_id: dataProject?.enc_customer_id,
            project_id: dataProject?.enc_id,
            type: 2,
            type_id: publishedId
        }
        try {
            const response = await postRequest(`pin-views`, formData);
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    useEffect(() => {
        if (id && open) {
            getProductDetails(id);
        }
    }, [id, open])

    const SetAsdestination = () => {
        if (type === 'from') {
            const dataToStore = {
                from: productDtls?.name,
                from_id: productDtls?.enc_id,
                from_floor_plan: productDtls?.floor_plan,
                type: productDtls?.type,
                from_floor_plan_id: productDtls?.enc_floor_plan_id,
                from_type_name: 'product',
                from_draft_id: productDtls?.draft_id
            }
            localStorage.setItem('from_location', JSON.stringify(dataToStore));
            setCurrentFloor()
            setCurrentFloor(productDtls?.enc_floor_plan_id)
            navigate(`/view-floorplan/${0}`);
            closes();
            setOpen(false);
        } else {
            const dataToStore = {
                to: productDtls?.name,
                to_id: productDtls?.enc_id,
                to_floor_plan: productDtls?.floor_plan,
                type: productDtls?.type,
                to_floor_plan_id: productDtls?.enc_floor_plan_id,
                to_type_name: 'product',
                to_draft_id: productDtls?.draft_id
            }
            localStorage.setItem('to_location', JSON.stringify(dataToStore));
            navigate(`/view-floorplan/${0}`);
            closes();
            setOpen(false);
        }
    }

    return (
        <>
            <Sheet isOpen={open} onClose={() => { setOpen(false); setProductDtls([]) }} detent='content-height'>
                <div className='modal-sheet-container'>

                    <Sheet.Container>
                        <Sheet.Content>
                            <Card className="mt-1">
                                <CardBody className='content-card'>
                                    <Row >
                                        <Col sm={12} className="d-flex  align-end">
                                            <h6 className='header mb-0'>{productDtls?.name}</h6>
                                            <div className='close-icon mt-1' onClick={close}>
                                                <AiOutlineClose />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <p className='mb-0 listcontent'>{productDtls?.floor_plan}</p>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Button onClick={SetAsdestination} className=' btn btn-warning-color' block> Set as {type === 'from' ? 'starting point' : 'destination'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <div className='bg-color container-fluid' style={{ overflow: 'auto' }}>
                                {productDtls?.image_path?.length > 0 &&

                                    <Row className=''>
                                        <Col sm={12} className='alignCenter mt-3'>
                                            <div className='img-container-product'>
                                                <img src={productDtls?.image_path?.length > 0 ? productDtls?.image_path[0] : ''} alt="floorplan" className='product-img' />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {productDtls?.product_code &&
                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Card >
                                                <CardBody>
                                                    <Row >
                                                        <Col sm={12}>
                                                            <h6 className='light-heading'>Details</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col sm={12}>
                                                            <div className="d-flex align-items-center align-end f-14 fw-500">
                                                                <p className='mb-0 '>Product code</p>
                                                                <p className='mb-0'>{productDtls?.product_code}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                {productDtls?.description &&
                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row >
                                                        <Col sm={12}>
                                                            <h6 className='light-heading'>Description</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col sm={12} className='f-14 fw-500'>
                                                            <p>{productDtls?.description}</p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                {productDtls?.specifications?.length > 0 &&
                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row >
                                                        <Col sm={12}>
                                                            <h6 className='light-heading'>Specifications</h6>
                                                        </Col>
                                                    </Row>
                                                    {productDtls?.specifications?.map((spec, index) => (
                                                        <Row >
                                                            <Col sm={12} key={index}>
                                                                <div className="d-flex align-items-center align-end f-14 fw-500">
                                                                    <p className='mb-0'>{spec.label}</p>
                                                                    <p className='mb-0'>{spec.value}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                {productDtls?.website &&
                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Card className='mb-3'>
                                                <CardBody style={{ padding: '0px' }}>
                                                    <Row justify="center">
                                                        <Col sm={12} >
                                                            <div className='' style={{ padding: '15px' }}>
                                                                <h6 className='light-heading'>Website</h6>
                                                                <p className='mb-0 text-color'>
                                                                    <a className='text-color' href={productDtls?.website?.startsWith('http') ? productDtls?.website : `https://${productDtls?.website}`} target="_blank" rel="noopener noreferrer">
                                                                        {productDtls?.website}
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }

                            </div>
                        </Sheet.Content>
                    </Sheet.Container>
                </div>
                <Sheet.Backdrop onClick={(e) => e.stopPropagation()} />
            </Sheet>
        </>
    );
}

export default SetProjuctDestination;
