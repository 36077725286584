import axios from 'axios';
import { environmentaldatas } from '../constant/defaultValues';
import { getCurrentUser } from '../helpers/utils';

const { baseURL } = environmentaldatas;
// const token = JSON.parse(sessionStorage.getItem('data'));
// const tocken="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMWYxZmVjMTFjNmRiMTM3MzJkY2U3NzcyNjA4NGFkNDMzYjIzNGE2ZDkwNGJiOGU1N2ZmNTAwMWE1MTk2Y2Q3MzZlZjg2ZGI1YzdkMzcyOTEiLCJpYXQiOjE2OTc2MDc3NjUuODM0MTEzLCJuYmYiOjE2OTc2MDc3NjUuODM0MTE2LCJleHAiOjE3MjkyMzAxNjUuODI3OTg5LCJzdWIiOiI5NSIsInNjb3BlcyI6W119.DOfcnsEg5F34G-0KuOEUZzhLobbY5UiQNt9uMqso-LwL8WxXpLLIql5gSO017OdP6Wrk4cTVsOOvJVBQa3eTCQxFDRricG8ZuiwOiLRHSCP3JS52rWImA9N_4CwOgNkcYAq39dJigCZtFr5h6XoSpxGpVLThEobQ8b6V6kb-7WspEqgznt4XZctkJzyZsWmUDWvj1F-Phu7DLIz7yc5s2qPKinYGBqJ32DCnFt8Q4GAJyR-yQTnT4GIVVlAlrMxu8AQ54lZJveg9gI3uWwcT5mIxYS-E0WCAzAuBas8F4oCnSLfnpGtULe0WCsSG_SaMKDfcXn16aJcipEMST3ZCtUnURH1iDxEQp1RgXkEHUuJnHOvOQgTf_PEIIY7kqZJmobiXoGIdgoZjAO-XMVz-0o7olfEIDv4vtPBJOWg6unRp1nM9GS8DoaddLGpDbDI5b8d_KQKjIWBwyM-6sZD4uHR3mHnK9eEFmUJHcO2Qz2KWmCXfzOs_mEPSgaBtv4k-1icLr1Cwag1T2lA0bvsnY2kgr20zHAHFscxWigldxYyuTjaUlk_Tfmhydj2qphMnClPZ7esE-kIjzxCKGqoIrAlrAAutHVltx6Er7qt0i9lckGWoXdiwMNp9O7zb4X8eUN5_tpy3Dpt5tYThfRaABvxm9MNVokntyYUSmOoozpo"

const axiosClient = axios.create();
axiosClient.defaults.baseURL = baseURL;
axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  // 'Content-Type': 'multipart/form-data',
  // Accept: 'application/json',
  // Authorization:`Bearer ${getCurrentUser()?.access_token}`,
  // Authorization:`Bearer ${tocken}`,

  
};
// axiosClient.defaults.timeout = 2000;
// axiosClient.defaults.withCredentials = true;

export function getRequest(URL) {

  axiosClient.defaults.responseType = undefined;
  // axiosClient.defaults.headers.Authorization = `Bearer ${tocken}`;

  return axiosClient.get(`/${URL}`).then((response) => response);
}

export function getRequestForDownload(URL) {

  const headers = {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};
  const config = { method: 'GET', url: URL, responseType: 'arraybuffer', headers };
  return axiosClient(config).then((response) => response);
}

export function postRequest(URL, payload) {
  // axiosClient.defaults.headers.Authorization = `Bearer ${tocken}`;
  axiosClient.defaults.responseType = undefined;
  return axiosClient
    .post(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}
export function putRequest(URL, payload) {
  // axiosClient.defaults.headers.Authorization = `Bearer ${tocken}`;
  axiosClient.defaults.responseType = undefined;
  return axiosClient
    .put(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function patchRequest(URL, payload) {
  // axiosClient.defaults.headers.Authorization = `Bearer ${tocken}`;
  axiosClient.defaults.responseType = undefined;
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  // axiosClient.defaults.headers.Authorization = `Bearer ${tocken}`;
  axiosClient.defaults.responseType = undefined;
  return axiosClient
    .delete(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function postRequestMultiform(URL, payload) {
  // axiosClient.defaults.headers.Authorization = `Bearer ${tocken}`;
  // axiosClient.defaults.headers['Content-Type'] =
  //   'application/x-www-form-urlencoded';
  axiosClient.defaults.responseType = 'blob';
  return axiosClient
    .post(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      const errors = error?.response?.data?.errors;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}
