

import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Button, Row, Col } from 'reactstrap';

const ImageSlider = ({ setSliders, sliders }) => {

  const renderSlides = () => {
    return sliders.map((item, index) => (
      <div key={index}>
        < Row >
          <Col sm={12} className=' mt-3'>
            <img src={item?.image_path} alt={`Slide ${index}`} className="img-location" style={{ borderRadius: '10px' }} />
          </Col>
        </Row>
      </div >
    ));
  };

  return (
    <div className="image-slider-container" >
      <Carousel
        showThumbs={false}
        showStatus={false}
        swipeable={true}
        autoPlay={true}
        interval={6000}
        infiniteLoop
        showArrows={false} // Hide arrows
        showIndicators={false} // Hide control dots
        autoFocus
      >
        {renderSlides()}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
