import React, { useState, useEffect, memo } from "react";
import { getRequest } from "../hooks/axiosClient";
import { environmentaldatas } from "../constant/defaultValues";
const { image_url } = environmentaldatas;

const LogoContainer = ({ adDetails, projectData }) => {
  const project = localStorage.getItem("project_data");
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (projectData?.status == 1) {
      setLogo(projectData?.project_logo ?? null);
    }
  }, [projectData]);

  return (
    <>
      <div
        className={
          adDetails && adDetails?.length > 0 ? "logo-wrpr" : "logo-wrpr2"
        }
      >
        {logo && <img src={logo} alt="logo" className="logo-img" style={{ objectFit: 'contain' }} />}
      </div>
    </>
  );
};

export default LogoContainer;
