/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
    Card,
    Row, Col,
    Modal,
    ModalBody,
    Input
} from 'reactstrap';
import { IoMdClose } from 'react-icons/io';


const verticalTransportModal = ({
    setModal, modal, toggle,
    verticalTransport,
    ChangeSvgColorPassingBE,
    onSelectVerticalTransport,
    selFloorData,
    destinationData,
    handleWheelchairCheck,
    onEndDirection
}) => {


    return (
        <>
            <Modal isOpen={modal} toggle={toggle} size='xl' style={{ zIndex: '999999 !important' }} backdrop={'static'} centered>
                <span className='modal-close-icon' >
                    <div onClick={() => {
                        onEndDirection('2')
                    }} className='rounded-circle close-icon-vt'>
                        <IoMdClose style={{ fontSize: '10px' }} />
                    </div>
                </span>
                <ModalBody style={{ padding: '30px' }} >
                    <Card >
                        <form
                            className="av-tooltip tooltip-label-bottom formGroups text-center"
                        >
                            <Row className=' '>
                                <Col sm={12}>
                                    <div className='col-sm-8 offset-sm-2 '>
                                        <h5 className="mb-2" style={{ fontSize: '18px', fontWeight: '700' }}>Your destination is on {destinationData?.to_floor_plan}</h5>
                                        <p style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}>Please select your preferred vertical transport method to reach your destination.</p>
                                        <div className='magical-words'>
                                            <div className='d-flex flex-wrap justify-content-around' style={{ width: '57%' }}>
                                                {verticalTransport?.map((item, index) => (
                                                    <React.Fragment key={item?.vtd_id}>
                                                        <div className={`card icon-div mr-2 mb-2 ${item?.noAccess && 'manageOpacity'}`} onClick={() => onSelectVerticalTransport(item)}>
                                                            <div dangerouslySetInnerHTML={{ __html: ChangeSvgColorPassingBE(item?.path, "#374046") }} />
                                                            <span style={{ color: '#000', fontSize: '14px', marginTop: '3px' }}>{item?.name}</span>
                                                        </div>
                                                        {index == 1 && <div className="w-100 d-md-none"></div>} {/* Add a new row after the second item */}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4 text-start'>
                                <Col md={12}>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <div className="checkbox-wrapper">
                                                <div className="d-flex f-12 justify-content-center">
                                                    <span style={{ fontSize: "1rem", marginRight: '10px' }}> Accessibile access required</span>
                                                    <Input type="checkbox"
                                                        name='is_wheelchair'
                                                        style={{ cursor: 'pointer' }}
                                                        onChange={(e) => {
                                                            handleWheelchairCheck(e)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form >
                    </Card >
                </ModalBody >
            </Modal >
        </>
    );
}

export default verticalTransportModal;
