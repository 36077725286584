import React from "react";
import { fabric } from "fabric";
import { hexToRgb } from "../helpers/hextoRgb";
import { escapeSpecialCharacters } from "../helpers/escapeSpecialCharacters";


const ChangeSvgColorPassingBE = (svg, color) => {
  const originalSvgString = svg;

  // Desired color (e.g., "#FF0000" for red)
  const desiredColor = color;

  // Create a DOM element from the SVG string
  const parser = new DOMParser();
  const doc = parser.parseFromString(originalSvgString, "image/svg+xml");
  const svgElement = doc.documentElement;

  // Find the <path> element with id="svg_1"
  const pathElement = svgElement.querySelector("#svg_1");

  // Update the "fill" attribute of the <path> element with the desired color
  pathElement?.setAttribute("fill", desiredColor);

  // Serialize the updated SVG element back to a string
  const updatedSvgString = new XMLSerializer().serializeToString(svgElement);

  // Now, updatedSvgString contains the modified SVG with the desired color
  return updatedSvgString;
};

const ObjectSendToBack = (name, canvas) => {
  canvas.forEachObject(function (obj) {
    if (obj.name == name) {
      canvas.sendToBack(obj);
    }
  });
};

const bringObjectsToFrontByName = (canvas, name) => {
  canvas.forEachObject(function (obj) {
    if (obj.name == name) {
      canvas.bringToFront(obj);
    }
  });
};

const addLocationPin = async (loc, color, canvas) => {
  console.log(loc, "boundary");
  //  canvas.remove(loc)

  if (loc?.boundary_color) {
    let polyObj = new fabric.Polygon(loc?.boundary_attributes, {
      name: "boundary",
      objectCaching: false,
      id: `boundary_${loc?.id}`,
      fill: loc.boundary_color ? hexToRgb(loc.boundary_color) : null,
      stroke: "grey",
      strokeWidth: Number(0),
      // lockMovementX: true, lockMovementY: true,
      originX: "center",
      originY: "center",
      selectable: false,
      lockScalingX: true, // Set lockScalingX to true
      lockScalingY: true, // Set lockScalingY to true
      ignoreZoom: true,
      skipAbsolute: true,
      types: "highlight_pin"
    });
    canvas.add(polyObj).renderAll();
    bringObjectsToFrontByName(canvas, "short_path");
    bringObjectsToFrontByName(canvas, "arrow");
    bringObjectsToFrontByName(canvas, "vertical");
    // ObjectSendToBack("boundary", canvas);
    // ObjectSendToBack("tracing", canvas);
    // canvas.bringToFront(polyObj);
  }
  // let singleLineLocationName = loc.id.replace(
  //     /\s/g,
  //     "\u00A0"
  // );
  let singleLineLocationName = escapeSpecialCharacters(loc.id);
  console.log(singleLineLocationName, "ddd");
  let fillColor = color;
  let textObj = new fabric.Text(loc.id, {
    left: loc.position?.x,
    top: loc.position?.y - 25,
    fill: "#646464",
    fontSize: 12,
    name: "location",
    perPixelTargetFind: true,
    position: "absolute",
    zIndex: 2000,
    selectable: false,
    originX: "center",
    originY: "center",
    fontFamily: `Arial`,
    ignoreZoom: true,
    types: "highlight_pin",
    ignoreZoom: true,
    skipAbsolute: true,

    initialTop: loc.position?.y - 25,
    initialLeft: loc.position?.x,
    initialZoom: 1

    // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
  });
  let locationIcon = `<svg width="${textObj.width + 5 + 5
    }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m10,0c-5.53061,0 -10,4.32465 -10,9.67615c0,1.56005 0.42857,3.08055 1.08163,4.44315c0.46939,0.9873 1.55102,2.4684 1.81633,2.8238c1.53061,2.0142 7.10204,8.0569 7.10204,8.0569c0,0 5.5918,-6.0427 7.102,-8.0569c0.2653,-0.3554 1.347,-1.8167 1.8164,-2.8238c0.653,-1.3626 1.0816,-2.8831 1.0816,-4.44315c0,-5.3515 -4.4694,-9.67615 -10,-9.67615z"/>
         <path id="svg_2" fill="white" d="m9.99998,17.7923c4.63242,0 8.38772,-3.6337 8.38772,-8.11613c0,-4.4824 -3.7553,-8.11611 -8.38772,-8.11611c-4.63242,0 -8.38775,3.63371 -8.38775,8.11611c0,4.48243 3.75533,8.11613 8.38775,8.11613z"/>
         <path id="svg_3" fill="${fillColor}" d="m10,16.331c3.7984,0 6.8776,-2.9795 6.8776,-6.65486c0,-3.67535 -3.0792,-6.65482 -6.8776,-6.65482c-3.79836,0 -6.87755,2.97947 -6.87755,6.65482c0,3.67536 3.07919,6.65486 6.87755,6.65486z"/>
         <path id="svg_4" fill="white" d="m13.1429,7.80014c1.2623,0 2.2857,-0.99021 2.2857,-2.21169c0,-1.22148 -1.0234,-2.2117 -2.2857,-2.2117c-1.2624,0 -2.2858,0.99022 -2.2858,2.2117c0,1.22148 1.0234,2.21169 2.2858,2.21169z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${textObj.width + 5
    }" height="20" rx="2" fill="#ffffffad" stroke="${fillColor}" stroke-width="1"/>
        
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineLocationName}</text>
      </g>
       </svg>`;

  let path = fabric.loadSVGFromString(
    locationIcon,
    function (objects, options) {
      let obj = fabric.util.groupSVGElements(objects, options);

      let backgroundRect = new fabric.Rect({
        left: loc.position.x,
        top: loc.position.y - 25,
        width: textObj.width + 10,
        height: textObj.height + 5,
        fill: "#ffffffad",
        stroke: fillColor,
        strokeWidth: 1,
        originX: "center",
        originY: "center",
        selectable: false,
        name: "location",
        rx: 2,
        ry: 2,
        ignoreZoom: true,
        skipAbsolute: true,
        types: "highlight_pin",

        initialTop: loc.position?.y - 25,
        initialLeft: loc.position?.x,
        initialZoom: 1
      });
      let group = new fabric.Group([backgroundRect, textObj], {
        name: "location",
        selectable: false,
        types: "highlight_pin",
        ignoreZoom: true,
        skipAbsolute: true,
        centeredScaling: true

        // initialTop: loc.position?.y - 25,
        // initialLeft: loc.position?.x,
        // initialZoom: 1,
        // left: loc.position.x,
        // top: loc.position.y - 25,
      });
      // canvas?.add(group);
      obj.set({
        id: loc.id,
        left: loc.left,
        top: loc.top,
        selectable: false,
        name: "location",
        enc_id: loc?.enc_id,
        uniformScaling: false,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        isBoundary: loc?.boundary_attributes ? true : false,
        draft_id: loc?.draft_id,
        position: loc?.position,
        types: "highlight_pin",
        ignoreZoom: true,
        skipAbsolute: true,
        boundary_color: loc?.boundary_color ?? null,
        boundary_attributes: loc?.boundary_attributes ?? null,
      });
      // canvas.remove(loc);
      canvas.add(obj)
      setTimeout(() => {
        canvas.bringToFront(obj);
      }, 300);
      // setTimeout(() => {
      //     canvas.remove(loc);
      // }, 500);

      // canvas.bringToFront(group)
    }
  );
};

const addProductPin = async (item, color, canvas) => {
  let fillColor = color;
  let singleLineProductName = escapeSpecialCharacters(item.id);
  let textObj = new fabric.Text(item.id, {
    left: item.position?.x,
    top: item.position?.y - 25,
    fill: "#646464",
    fontSize: 12,
    name: "product",
    perPixelTargetFind: true,
    position: "absolute",
    zIndex: 2000,
    selectable: false,
    originX: "center",
    originY: "center",
    fontFamily: `Arial`,
    types: "highlight_pin",

    initialTop: item.position?.y - 25,
    initialLeft: item.position?.x,
    initialZoom: 1

    // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
  });
  let productIcon = `<svg width="${textObj.width + 5 + 5
    }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m10,0c-5.52783,0 -10,4.32764 -10,9.67682c0,1.56018 0.42227,3.08318 1.07486,4.43908c0.47985,1.003 1.5547,2.4703 1.82341,2.8232c1.51632,2.0245 7.10173,8.0609 7.10173,8.0609c0,0 5.5854,-6.0364 7.1017,-8.0609c0.2687,-0.3529 1.3436,-1.8202 1.8234,-2.8232c0.6526,-1.3559 1.0749,-2.8789 1.0749,-4.43908c0,-5.34918 -4.4722,-9.67682 -10,-9.67682z"/>
         <path id="svg_2" fill="white" d="m9.99999,17.7935c4.63241,0 8.38771,-3.634 8.38771,-8.11669c0,-4.48269 -3.7553,-8.11664 -8.38771,-8.11664c-4.63241,0 -8.38772,3.63395 -8.38772,8.11664c0,4.48269 3.75531,8.11669 8.38772,8.11669z"/>
         <path id="svg_3" fill="${fillColor}" d="m10,16.3262c3.795,0 6.8714,-2.9771 6.8714,-6.64938c0,-3.67232 -3.0764,-6.64933 -6.8714,-6.64933c-3.79497,0 -6.8714,2.97701 -6.8714,6.64933c0,3.67228 3.07643,6.64938 6.8714,6.64938z"/>
         <path id="svg_4" fill="white" d="m10,11.9057c1.2721,0 2.3033,-0.9979 2.3033,-2.22888c0,-1.23094 -1.0312,-2.22882 -2.3033,-2.22882c-1.27206,0 -2.30327,0.99788 -2.30327,2.22882c0,1.23098 1.03121,2.22888 2.30327,2.22888z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${textObj.width + 5
    }" height="20" rx="2" fill="#ffffffad" stroke="${fillColor}" stroke-width="1"/>
        
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineProductName}</text>
      </g>
       </svg>`;
  let path = fabric.loadSVGFromString(productIcon, function (objects, options) {
    let obj = fabric.util.groupSVGElements(objects, options);
    let singleLineLocationName = escapeSpecialCharacters(item.id);
    let textObj = new fabric.Text(item.id, {
      left: item.position?.x,
      top: item.position?.y - 25,
      fill: "#646464",
      fontSize: 12,
      name: "product",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
      originX: "center",
      originY: "center",
      fontFamily: `Arial`,
      types: "highlight_pin",

      initialTop: item.position?.y - 25,
      initialLeft: item.position?.x,
      initialZoom: 1

      // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
    });
    let backgroundRect = new fabric.Rect({
      left: item.position?.x,
      top: item.position?.y - 25,
      width: textObj.width + 10,
      height: textObj.height + 5,
      fill: "#ffffffad",
      stroke: fillColor,
      strokeWidth: 1,
      originX: "center",
      originY: "center",
      selectable: false,
      name: "product",
      rx: 2,
      ry: 2,
      types: "highlight_pin",

      initialTop: item.position?.y - 25,
      initialLeft: item.position?.x,
      initialZoom: 1
    });
    let group = new fabric.Group([backgroundRect, textObj], {
      name: "product",
      selectable: false,
      types: "highlight_pin"

      // initialTop: item.position?.y - 25,
      // initialLeft: item.position?.x,
      // initialZoom: 1,
      // left: item.position?.x,
      // top: item.position?.y - 25,
    });
    // canvas?.add(group);

    obj.set({
      left: item.left,
      top: item.top,
      // selectable: prod?.enc_id === selProductDtls?.enc_id ? true : false,
      selectable: false,
      name: "product",
      id: item.id,
      enc_id: item?.enc_id,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      types: "highlight_pin"
    });

    canvas.add(obj).renderAll();
    setTimeout(() => {
      canvas.bringToFront(obj);
    }, 300);
  });
};

const addBeaconPin = async (item, color, canvas) => {
  let fillColor = color;
  let singleLineBeaconName = escapeSpecialCharacters(item.id);
  let textObj = new fabric.Text(item.id, {
    left: item.position?.x,
    top: item.position?.y - 22,
    fill: "#646464",
    fontSize: 12,
    name: "beacon",
    backgroundColor: "white",
    perPixelTargetFind: true,
    position: "absolute",
    zIndex: 2000,
    selectable: false,
    originX: "center",
    originY: "center",
    fontFamily: `Arial`,
    types: "highlight_pin",

    initialTop: item.position?.y - 22,
    initialLeft: item.position?.x,
    initialZoom: 1

    // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
  });

  let beaconIcon = `<svg width="${textObj.width + 5 + 5
    }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
    <g transform="translate(${textObj.width / 2 - 5}, 0)">
     <title>Layer 1</title>
     <title>Layer 1</title>
     <path id="svg_1" fill="${fillColor}" d="m9.95319,0.04415c-0.31035,-0.02209 -0.62066,-0.04415 -0.931,-0.04415c-0.93104,0 -1.83988,0.1325 -2.68225,0.39753c-0.84236,0.26503 -1.64041,0.64051 -2.37194,1.1264c-2.39409,1.61227 -3.968,4.3509 -3.968,7.46502c0,0.35337 0.02216,0.70674 0.0665,1.06015c0,0 0,0.0442 0,0.0662c0.04434,0.3313 0.11088,0.6847 0.17738,1.016c0.0665,0.2871 0.15516,0.5963 0.266,0.8834c0.04433,0.1105 0.08866,0.243 0.133,0.3534c0.02216,0.0662 0.06652,0.1325 0.08868,0.2209c1.39656,3.1803 4.56647,5.411 8.26844,5.411c3.702,0 6.8941,-2.2307 8.2685,-5.411c0.0222,-0.0663 0.0665,-0.1326 0.0887,-0.2209c0.0443,-0.1104 0.0887,-0.2429 0.133,-0.3534c0.0887,-0.2871 0.1995,-0.5742 0.266,-0.8834c0.0887,-0.3313 0.133,-0.6626 0.1773,-0.9939c0,0 0,-0.0441 0,-0.0662c0.0443,-0.35341 0.0665,-0.70677 0.0665,-1.06015c0,-0.3092 0,-0.61843 -0.0443,-0.92763c-0.4212,-4.2405 -3.8128,-7.59753 -8.04682,-8.01717l0.04431,-0.0221z"/>
     <path id="svg_2" fill="white" d="m9.02202,16.5206c4.17478,0 7.55908,-3.3719 7.55908,-7.53129c0,-4.15942 -3.3843,-7.5313 -7.55908,-7.5313c-4.17479,0 -7.55913,3.37188 -7.55913,7.5313c0,4.15939 3.38434,7.53129 7.55913,7.53129z"/>
     <path id="svg_3" fill="${fillColor}" d="m9.0223,15.1733c3.428,0 6.2069,-2.7687 6.2069,-6.18406c0,-3.41536 -2.7789,-6.18406 -6.2069,-6.18406c-3.42797,0 -6.20687,2.7687 -6.20687,6.18406c0,3.41536 2.7789,6.18406 6.20687,6.18406z"/>
     <path id="svg_4" fill="white" d="m11.8594,7.24421c1.1386,0 2.0616,-0.91962 2.0616,-2.05401c0,-1.13438 -0.923,-2.05397 -2.0616,-2.05397c-1.1386,0 -2.06155,0.91959 -2.06155,2.05397c0,1.13439 0.92295,2.05401 2.06155,2.05401z"/>
    </g>
    <g>
    <!-- Rectangle with border -->
    <rect x="5" y="-26" width="${textObj.width + 5
    }" height="20" rx="2" fill="#ffffffad" stroke="${fillColor}" stroke-width="1"/>
    
    <!-- Text -->
    <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineBeaconName}</text>
  </g>
   </svg>`;

  let path = fabric.loadSVGFromString(beaconIcon, function (objects, options) {
    let obj = fabric.util.groupSVGElements(objects, options);
    let singleLineBeaconName = escapeSpecialCharacters(item.id);
    let textObj = new fabric.Text(item.id, {
      left: item.position?.x,
      top: item.position?.y - 22,
      fill: "#646464",
      fontSize: 12,
      name: "beacon",
      backgroundColor: "white",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
      originX: "center",
      originY: "center",
      fontFamily: `Arial`,
      types: "highlight_pin",

      initialTop: item.position?.y - 22,
      initialLeft: item.position?.x,
      initialZoom: 1

      // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
    });
    // canvas?.add(textObj);
    let backgroundRect = new fabric.Rect({
      left: item.position?.x,
      top: item.position?.y - 22,
      width: textObj.width + 12,
      height: textObj.height + 5,
      fill: "#ffffffad",
      stroke: fillColor,
      strokeWidth: 1,
      originX: "center",
      originY: "center",
      selectable: false,
      name: "beacon",
      rx: 2,
      ry: 2,
      types: "highlight_pin",

      initialTop: item.position?.y - 22,
      initialLeft: item.position?.x,
      initialZoom: 1
    });
    let group = new fabric.Group([backgroundRect, textObj], {
      name: "beacon",
      selectable: false,
      types: "highlight_pin"

      // initialTop: item.position?.y - 22,
      // initialLeft: item.position?.x,
      // initialZoom: 1,
      // left: item.position?.x,
      // top: item.position?.y - 22,
    });
    // canvas?.add(group);
    obj.set({
      left: item.left,
      top: item.top,
      selectable: false,
      name: "beacon",
      id: item.id,
      enc_id: item?.enc_id,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      types: "highlight_pin"
    });

    canvas.add(obj).renderAll();
    setTimeout(() => {
      canvas.bringToFront(obj);
    }, 300);
  });
};

const addAmenityPin = async (item, color, canvas) => {
  let fillColor = color;
  let amenityIcon = ChangeSvgColorPassingBE(item?.icon_path, fillColor);

  let path = fabric.loadSVGFromString(amenityIcon, function (objects, options) {
    let obj = fabric.util.groupSVGElements(objects, options);

    obj.set({
      left: item.left,
      top: item.top,
      // selectable: item?.enc_id === selProductDtls?.enc_id ? true : false,
      selectable: false,
      name: "amenity",
      id: item.id,
      enc_id: item?.enc_id,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      icon_path: item?.icon_path,
      icon_color: fillColor,
      position: item?.position,
      draft_id: item?.amenity_id,
      types: "highlight_pin"
    });
    canvas.add(obj).renderAll();
    setTimeout(() => {
      canvas.bringToFront(obj);
    }, 300);
  });
};

const addSafetyPin = async (item, color, canvas) => {
  let fillColor = color;
  let amenityIcon = ChangeSvgColorPassingBE(item?.icon_path, fillColor);

  let path = fabric.loadSVGFromString(amenityIcon, function (objects, options) {
    let obj = fabric.util.groupSVGElements(objects, options);

    obj.set({
      left: item.left,
      top: item.top,
      // selectable: item?.enc_id === selProductDtls?.enc_id ? true : false,
      selectable: false,
      name: "safety",
      id: item.id,
      enc_id: item?.enc_id,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      icon_path: item?.icon_path,
      icon_color: fillColor,
      types: "highlight_pin"
    });

    canvas.add(obj).renderAll();
    setTimeout(() => {
      canvas.bringToFront(obj);
    }, 300);
  });
};

const addVerticalPin = async (item, color, canvas, name) => {
  if (!name) {
    let fillColor = color;
    let amenityIcon = ChangeSvgColorPassingBE(item?.icon_path, fillColor);
    let path = fabric.loadSVGFromString(
      amenityIcon,
      function (objects, options) {
        let obj = fabric.util.groupSVGElements(objects, options);

        obj.set({
          left: item.left,
          top: item.top,
          // selectable: item?.enc_id === selProductDtls?.enc_id ? true : false,
          selectable: false,
          name: "vertical",
          id: item.id,
          enc_id: item?.enc_id,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          icon_path: item?.icon_path,
          icon_color: fillColor,
          types: "highlight_pin"
        });

        canvas.add(obj).renderAll();
        setTimeout(() => {
          canvas.bringToFront(obj);
        }, 300);
      }
    );
  } else {
    // if type == to
    let fillColor = color;
    let title = name;
    let textObj = new fabric.Text(title, {
      left: item.position?.x,
      top: item.position?.y - 22,
      fill: "#646464",
      fontSize: 12,
      name: "vertical",
      backgroundColor: "white",
      perPixelTargetFind: true,
      position: "absolute",
      zIndex: 2000,
      selectable: false,
      originX: "center",
      originY: "center",
      fontFamily: `Arial`,
      types: "highlight_pin",

      initialTop: item.position?.y - 22,
      initialLeft: item.position?.x,
      initialZoom: 1

      // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
    });

    let amenityIcon = ChangeSvgColorPassingBE(item?.icon_path, fillColor);
    // const transformedSVG = addTransformToSVG(amenityIcon, `translate(${textObj.width / 2 - 5}, 0)`);
    let svg = `<svg width="${textObj.width + 5 + 5
      }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
    <g transform="translate(0, 0)">
    ${amenityIcon}
    </g>
    <g >
    <!-- Rectangle with border -->
    <rect x="5" y="-26" width="${textObj.width + 5
      }" height="20" rx="2" fill="#ffffffad" stroke="${fillColor}" stroke-width="1"/>
    
    <!-- Text -->
    <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${title}</text>
  </g>
   </svg>`;

    let path = fabric.loadSVGFromString(svg, function (objects, options) {
      let obj = fabric.util.groupSVGElements(objects, options);
      // let textObj = new fabric.Text(title, {
      //   left: item.position?.x,
      //   top: item.position?.y - 25,
      //   fill: "#646464",
      //   fontSize: 12,
      //   name: "vertical",
      //   perPixelTargetFind: true,
      //   position: "absolute",
      //   zIndex: 2000,
      //   selectable: false,
      //   originX: "center",
      //   originY: "center",
      //   fontFamily: `Arial`,
      //   types: "highlight_pin",

      //   initialTop: item.position?.y - 25,
      //   initialLeft: item.position?.x,
      //   initialZoom: 1

      //   // fontFamily: `'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`
      // });
      // let backgroundRect = new fabric.Rect({
      //   left: item.position?.x,
      //   top: item.position?.y - 25,
      //   width: textObj.width + 10,
      //   height: textObj.height + 5,
      //   fill: "#ffffffad",
      //   stroke: fillColor,
      //   strokeWidth: 1,
      //   originX: "center",
      //   originY: "center",
      //   selectable: false,
      //   name: "product",
      //   rx: 2,
      //   ry: 2,
      //   types: "highlight_pin",

      //   initialTop: item.position?.y - 25,
      //   initialLeft: item.position?.x,
      //   initialZoom: 1
      // });
      // let group = new fabric.Group([backgroundRect, textObj], {
      //   name: "vertical",
      //   selectable: false,
      //   types: "highlight_pin"
      // });
      obj.set({
        left: item.left,
        top: item.top,
        // selectable: item?.enc_id === selProductDtls?.enc_id ? true : false,
        selectable: false,
        name: "vertical",
        id: item.id,
        enc_id: item?.enc_id,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        icon_path: item?.icon_path,
        icon_color: fillColor,
        types: "highlight_pin"
      });

      canvas.add(obj).renderAll();
      setTimeout(() => {
        canvas.bringToFront(obj);
      }, 300);
    });
  }
};

function addTransformToSVG(svgContent, transformValue) {
  // Parse the SVG string into an SVG document
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');

  // Find the first <g> element in the SVG
  const gElement = svgDoc.querySelector('g');

  // Add the transform attribute to the <g> element
  gElement.setAttribute('transform', transformValue);

  // Serialize the SVG document back to a string
  const serializer = new XMLSerializer();
  const transformedSVG = serializer.serializeToString(svgDoc);

  return transformedSVG;
}

export {
  addLocationPin,
  addProductPin,
  addBeaconPin,
  addAmenityPin,
  addSafetyPin,
  addVerticalPin,
  ChangeSvgColorPassingBE
};
