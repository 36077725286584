/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { CardBody, Card, Row, Col, Button } from 'reactstrap';
import { AiOutlineClose } from 'react-icons/ai'
import {  useNavigate } from 'react-router-dom';
import { getRequest } from '../hooks/axiosClient';
import Sheet from 'react-modal-sheet';

const SetLocation = ({
    open,
    setOpen,
    id,
    type,
    destination,
    closes,
    setCurrentFloor,
    setFromDestinationData,
    click
}) => {
    const navigate = useNavigate();
    const close = () => {
        setOpen(false);
        setdetails([])
    }
    const [details, setdetails] = useState([]);

    const getProductDetails = async (id) => {
        try {
            const response = await getRequest(`destinations-info/${id}/${type}`);
            const data = response.data.data ?? [];
            console.log(data, 'data')
            const dataToStore = {
                ...data,
                from: data?.name,
                from_id: data?.enc_id,
                from_floor_plan: data?.floor_plan,
                type: data?.type,
                from_floor_plan_id: data?.enc_floor_plan_id,
                from_type_name: data?.type == 3 ? 'beacon' : data?.type == 4 ? 'amenity' : data?.type == 5 ? 'safety' : data?.type == 6 ? 'vertical' : undefined,
                from_draft_id: data?.draft_id
            }
            // setCurrentFloor(data?.enc_floor_plan_id)
            if (data?.type == 6) {
                const vertical = localStorage.getItem('vertical');
                console.log(vertical, 'verticalllll')
                let parse = vertical ? JSON.parse(vertical) : null

                if (destination == 'from') {
                    // setFromDestinationData(parse)
                }

                setdetails(parse)
                // setCurrentFloor(parse?.enc_floor_plan_id)
            } else {
                setdetails(dataToStore);
                if (destination == 'from') {
                    // setFromDestinationData(dataToStore)
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    useEffect(() => {
        if (id && open) {
            getProductDetails(id);
        }
    }, [id, open])


    const SetAsdestination = () => {
        if (destination === 'from') {
            console.log(type)
            const dataToStore = {
                from: details?.name,
                from_id: details?.enc_id,
                from_floor_plan: details?.floor_plan,
                type: details?.type,
                from_floor_plan_id: details?.enc_floor_plan_id,
                from_type_name: details?.type == 3 ? 'beacon' : details?.type == 4 ? 'amenity' : details?.type == 5 ? 'safety' : details?.type == 6 ? 'vertical' : undefined,
                from_draft_id: details?.draft_id
            }
            localStorage.setItem('from_location', JSON.stringify(details?.type == 6 ? details : dataToStore));
            setCurrentFloor(dataToStore?.enc_floor_plan_id)
            navigate(`/view-floorplan/${0}`);
            closes();
            setOpen(false);
        } else {
            const dataToStore = {
                to: details?.name,
                to_id: details?.enc_id,
                to_floor_plan: details?.floor_plan,
                type: details?.type,
                to_floor_plan_id: details?.enc_floor_plan_id,
                to_type_name: details?.type == 3 ? 'beacon' : details?.type == 4 ? 'amenity' : details?.type == 5 ? 'safety' : details?.type == 6 ? 'vertical' : undefined,
                to_draft_id: details?.draft_id
            }
            console.log(dataToStore, 'todatasource')
            localStorage.setItem('to_location', JSON.stringify(details?.type == 6 ? details : dataToStore));
            navigate(`/view-floorplan/${0}`);
            closes();
            setOpen(false);
        }
    }

    return (
        <>
            <Sheet isOpen={open} onClose={() => { setOpen(false); setdetails([]) }} >
                <div className='modal-sheet-container'>
                    <Sheet.Container>
                        <Sheet.Content>
                            <Card className='mt-1'>
                                <CardBody className='content-card'>
                                    <Row >
                                        <Col sm={12} className="d-flex align-items-center align-end">
                                            <h6 className='header'>{details?.name ?? details?.from}</h6>
                                            <div className='close-icon' onClick={close}>
                                                <AiOutlineClose />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <p className='mb-0'>{details?.floor_plan ?? details?.from_floor_plan}</p>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Button size="small" key="4" onClick={SetAsdestination} type="primary" className='btn btn-primary' block> Set as {destination === 'from' ? 'starting point' : 'destination'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Sheet.Content>
                    </Sheet.Container>
                </div>
                <Sheet.Backdrop onClick={(e) => e.stopPropagation()} />
            </Sheet >
        </>
    );
}

export default SetLocation;
