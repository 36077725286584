class Graph {
    constructor() {
        this.nodes = new Set();
        this.edges = {};
        this.positions = {};
    }

    addNode(node) {
        this.nodes.add(node);
        if (!this.edges[node]) {
            this.edges[node] = {};
        }
    }

    addEdge(node1, node2) {
        let position1 = this.positions[node1];
        let position2 = this.positions[node2];
        const distance = Math.sqrt(
            (position1?.x - position2?.x) ** 2 + (position1?.y - position2?.y) ** 2
        );
        ////console.log(this.edges, "edges 1");
        this.edges[`${node1}`][`${node2}`] = distance;
        this.edges[`${node2}`][`${node1}`] = distance;
    }

    addPosition(node, x, y) {
        this.positions[node] = { x, y };
    }
    restoreEdges(edges) {
        this.edges = edges ?? {};
    }
    restorePositions(positions) {
        this.positions = positions ?? {};
    }
    getNeighbors(node) {
        return Object.keys(this.edges[node]);
    }

    updateAllEdges(edges) {
        this.edges = edges;
    }
    getPositions() {
        return this.positions;
    }
    getEdges() {
        return this.edges;
    }
}
export default Graph;