import React, { useState, useEffect } from 'react';
import { getRequest } from '../hooks/axiosClient';
import Select from 'react-select';

const customStyles = {
    control: (provided) => ({
        ...provided,
        color: 'black',
        border: 'none',
        borderRadius: '10px'
    }),
};
const FloorsList = ({
    values, onchange, disabled, adDetails,
    setFloorsListrOrder,
    setFloorPlans
}) => {
    const project = localStorage.getItem('project_data');
    const [floors, setFloors] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);

    const getFloorList = async (value) => {
        try {
            const response = await getRequest(`levels/${value?.project_id}`);
            const data = response.data ?? [];
            const floors = data.map((floor) => ({
                value: floor.enc_id,
                label: floor?.floor_plan,
                id: floor.enc_id,
            }))
            console.log(data, 'floor list')
            setFloors(floors)
            if (setFloorsListrOrder) {
                setFloorsListrOrder(data)
                setFloorPlans(data)
            }
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        const data = JSON.parse(project)
        getFloorList(data)
    }, [project]);

    useEffect(() => {
        const value = floors?.find((item) => values == item?.value)
        setDefaultValue(value)
    }, [values, floors])


    return (
        <>
            <div className={(adDetails && adDetails?.length > 0) ? 'floors' : 'floors1'} hidden={floors?.length === 0 || floors?.length === 1}>
                <Select
                    value={defaultValue}
                    options={floors}
                    styles={customStyles}
                    onChange={(e) => onchange(e?.value)}
                    defaultValue={defaultValue}
                    isDisabled={disabled}
                    isSearchable={false}

                />
            </div>
        </>
    );
};

export default FloorsList;