import React, { useEffect } from 'react';
import { Main } from '../Styles/styled';
import notFoundImage from '../assets/img/fmw-noaccess.svg';
import noFoundBeacon from '../assets/img/fmw-qrBeaconDelete.svg';
import LogoImage from '../assets/img/Logo.svg';
import { useParams } from "react-router-dom";
import { decode } from '../helpers/utils';


const NotFound = () => {
    let { id } = useParams();
    id = id && decode(id);

    useEffect(() => {
        localStorage.removeItem('from_location');
        localStorage.removeItem('to_location');
        localStorage.removeItem('project_data');
    }, [])

    return (
        <>
            <Main style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {id == 1 ? (
                    <div className='not-found-div'>
                        <img src={LogoImage} alt="floorplan" style={{ width: '55%' }} />
                        <img src={notFoundImage} alt='logo' style={{ width: '50%' }} className='mt-4' />
                        <div className='text-center'>
                            <p className='oops mt-3'>Oops...</p>
                            <p style={{ fontWeight: '600' }}>This project is currently inactive, please activate this project in order to gain access.</p>
                        </div>
                    </div>
                ) : (
                    <div className='not-found-div'>
                        <img src={LogoImage} alt="floorplan" style={{ width: '55%' }} />
                        <img src={noFoundBeacon} alt='logo' style={{ width: '50%' }} className='mt-4' />
                        <div className='text-center'>
                            <p className='oops mt-3'>Oops...</p>
                            <p style={{ fontWeight: '600' }}>Sorry, this QR code has been deleted or is invalid. Please contact the administrator for assistance.</p>
                        </div>
                    </div>
                )}
            </Main>
        </>
    );
};

export default NotFound;