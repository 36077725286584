import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import ContextProvider from './providers/ContextProvider';
import AppRoutes from './routes/AppRoutes';
import { Toaster } from 'react-hot-toast';
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

function App() {



  useEffect(() => {
    // Disable pinch zooming on iOS devices
    const handleGestureStart = (e) => {
      e.preventDefault();
    };

    document.addEventListener('gesturestart', handleGestureStart);

    // Prevent double tap zoom on iOS devices
    let lastTouchEnd = 0;
    const handleTouchEnd = (e) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        e.preventDefault();
      }
      lastTouchEnd = now;
    };

    document.addEventListener('touchend', handleTouchEnd, false);

    // let lastTouchY = 0;

    // const handleTouchStart = (e) => {
    //   lastTouchY = e.touches[0].clientY;
    // };

    // const handleTouchMove = (e) => {
    //   const touchY = e.touches[0].clientY;
    //   const touchYDelta = touchY - lastTouchY;
    //   lastTouchY = touchY;

    //   // Check if the user is trying to scroll up past the top of the page
    //   if (window.scrollY === 0 && touchYDelta > 0) {
    //     e.preventDefault();
    //   }

    //   // Check if the user is trying to scroll down past the bottom of the page
    //   const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    //   if (window.scrollY === scrollableHeight && touchYDelta < 0) {
    //     e.preventDefault();
    //   }
    // };

    // document.addEventListener('touchstart', handleTouchStart, { passive: false });
    // document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('gesturestart', handleGestureStart);
      document.removeEventListener('touchend', handleTouchEnd);
      // document.removeEventListener('touchstart', handleTouchStart);
      // document.removeEventListener('touchmove', handleTouchMove);

    };
  }, []);



  const customToastStyle = {
    // width: window.innerWidth, // Set the desired width
    borderRadius: '10px',

  };

  return (
    <>
      <ContextProvider>
        <AppRoutes />
      </ContextProvider>
      {/* <ToastContainer theme="colored"
          position="bottom-center" hideProgressBar="true" autoClose="2000" closeButton={false}
          style={customToastStyle}
          
        /> */}
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
          // closeTimeout:
        }}
        containerStyle={{
          zIndex: 99999999, // Adjust the z-index as needed
        }}
      />
    </>
  );
}

export default App;
