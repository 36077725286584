export const hexToRgb = (hex) => {
    hex = hex?.replace(/^#/, "");
    const r = parseInt(hex?.slice(0, 2), 16);
    const g = parseInt(hex?.slice(2, 4), 16);
    const b = parseInt(hex?.slice(4, 6), 16);
    const a = hex?.slice(6, 8);
    if (a) {
      var o = parseInt(hex?.slice(6, 8), 16) / 255;
    }
    return `rgba(${r}, ${g}, ${b}, ${o ? o.toFixed(2) : 0.4})`;
  };
