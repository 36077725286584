import React, { useState, useEffect, useRef } from 'react';
import { environmentaldatas } from '../constant/defaultValues';
import SetDestination from './SetDestination';
import { getRequest, postRequest } from "../hooks/axiosClient";
import SetProjuctDestination from './setProductDestination';

const { image_url } = environmentaldatas;

const ImageSlider = ({ adDetails, setFromDestinationData, setCurrentFloor }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [types, setType] = useState();
    const [id, setId] = useState();
    const [openLocation, setOpenLocation] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);

    useEffect(() => {
        if (adDetails.length === 0) {
            return;
        } else {
            const slideToNextImage = () => {
                // const prevIndex = currentIndex;
                const currentImageId = adDetails[currentIndex]?.enc_id;
                const nextIndex = (currentIndex  + 1) % adDetails.length;
                if (currentImageId) {
                    console.log('slider:',currentImageId)
                    getPinViews(currentImageId); 
                }
                setCurrentIndex(nextIndex);
                // const currentImageDuration = adDetails[prevIndex]?.duration || 0;
                // setTimeout(() => {
                //     getPinViews(adDetails[prevIndex]?.enc_id); 
                // }, currentImageDuration * 1000);

            };
            const time = adDetails[currentIndex]?.duration * 1000
            const intervalId = setInterval(slideToNextImage, time);
            return () => clearInterval(intervalId);
        }

    }, [adDetails, currentIndex]);

    const handleClickImage = (ad) => {
        getInteraction(ad)
        if (ad?.link == null) {
            if(ad?.location_id){
                setOpenLocation(true);
                setId(ad?.location_id);
            }else{
                setOpenProduct(true);
                setId(ad?.product_id);
            }
            setType('destination');
            console.log(ad)
        } else {
            const absoluteURL = ad?.link.startsWith('http://') || ad?.link.startsWith('https://')
                ? ad?.link
                : 'http://' + ad?.link;
            window.open(absoluteURL, '_blank');
        }
    }

    const getInteraction = async (Data) => {
        const project = localStorage.getItem("project_data");
        const data = project ? JSON.parse(project) : null;
        const formData = {
            customer_id: data?.enc_customer_id,
            project_id: data?.enc_id,
            type: 3,
            type_id: Data?.enc_id
        }
        try {
            const response = await postRequest(`pin-interactions`, formData);
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    const getPinViews = async (publishedId) => {
        const project = localStorage.getItem("project_data");
        const data = project ? JSON.parse(project) : null;
        const formData = {
            customer_id: data?.enc_customer_id,
            project_id: data?.enc_id,
            type: 3,
            type_id: publishedId
        }
        try {
            const response = await postRequest(`pin-views`, formData);
            //  setHours()
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    return (
        <>
            <div style={{ width: '100%', height: '57px', overflow: 'hidden', position: 'absolute', zIndex: 100 }}>
                {adDetails.map((ad, index) => (
                    <img 
                        key={index}
                        src={image_url + ad?.ad_image}
                        alt={ad?.banner_name}
                        style={{
                            width: '100%',
                            height: '100%',
                            display: index === currentIndex ? 'block' : 'none',
                        }}
                        onClick={() => { handleClickImage(ad) }}
                    />
                ))}
            </div>
            <SetDestination
                open={openLocation}
                setOpen={setOpenLocation}
                type={types} id={id}
                closes={() => { setOpenLocation(false) }}
                setCurrentFloor={setCurrentFloor}
                setFromDestinationData={setFromDestinationData}
            />
             <SetProjuctDestination
                open={openProduct}
                setOpen={setOpenProduct}
                type={types}
                id={id} closes={() => { setOpenProduct(false) }}
                setCurrentFloor={setCurrentFloor}
                setFromDestinationData={setFromDestinationData}
            />
        </>
    );
};
export default ImageSlider;
