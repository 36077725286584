/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Row, Col, Button, Card, CardBody, Spinner } from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Main } from '../Styles/styled';
import './page.css';
import { Formik, Field } from "formik";
import * as Yup from 'yup';
import { postRequest } from '../hooks/axiosClient';
import toast, { Toaster } from 'react-hot-toast';
import { AiOutlineClose } from 'react-icons/ai'

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.'),
    email: Yup.string().required("This field is required.").email("Invalid email format."),
    description: Yup.string().required('This field is required.'),
});

const ReportIssueForm = ({ setOpen, open }) => {
    const initialValues = {
        name: '',
        email: '',
        description: ''
    }

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmitReport = async (values, setFieldError) => {
        setLoading(true)
        const project = localStorage.getItem('project_data');
        let ProjectData = JSON.parse(project)
        let value = {
            customer_id: ProjectData?.enc_customer_id,
            name: values?.name,
            email: values?.email,
            description: values?.description,
            project_id: ProjectData?.project_id
        }
        try {
            const response = await postRequest(`report-issue`, value);
            const data = response.data ?? [];
            toast.success(response?.response?.data?.message);
            navigate(`/view-floorplan/0`);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const close = () => {
        navigate(`/view-floorplan/0`)
    }

    return (
        <>
            <Main>
                <Card className='mt-1'>
                    <CardBody className='content-card' style={{ backgroundColor: 'white' }}>
                        <div className='d-flex  align-end mt-3' >
                            <h6 className='header '>Report an Issue</h6>
                            <div className='close-icon' onClick={close}>
                                <AiOutlineClose />
                            </div>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={ValidationSchema}
                            onSubmit={(values, setFieldError) => {
                                handleSubmitReport(values, setFieldError);
                            }}
                        >
                            {({
                                errors,
                                values,
                                touched,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                setFieldError,
                            }) => (
                                <form
                                    onSubmit={(e) => handleSubmit(e, setFieldError)}
                                    className="w-100"
                                >
                                    <div >
                                        <Row>
                                            <Col sm={12}>
                                                <p className='report-msge mb-2 mt-2'>
                                                    Find My Way provides a platform for businesses to develop their wayfinding solutions independently and does not guarantee the accuracy of any maps or navigation paths generated through our software. It is the exclusive responsibility of businesses using Find My Way to maintain the accuracy of their wayfinding data. To report any issues with the navigation system, please use the form below to contact the respective business entity.
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <p style={{ color: '#818283' }} className='mb-3'>
                                                    * Indicates required field.
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={12}>
                                                <Field
                                                    placeholder="Name*"
                                                    type="text"
                                                    name='name'
                                                    className=' form-control input-style'
                                                    value={values?.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && touched.name ? (
                                                    <div className="text-danger mt-1">
                                                        {errors.name}
                                                    </div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col sm={12}>
                                                <Field
                                                    placeholder="Email Address*"
                                                    type="text"
                                                    name='email'
                                                    className=' form-control input-style'
                                                    value={values?.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && touched.email ? (
                                                    <div className="text-danger mt-1">
                                                        {errors.email}
                                                    </div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col sm={12}>
                                                <Field
                                                    placeholder="Issue Details*"
                                                    component="textarea"
                                                    type="text"
                                                    name='description'
                                                    rows={5}
                                                    className='form-control input-style'
                                                    value={values?.description}
                                                    onChange={handleChange}
                                                />
                                                {errors.description && touched.description ? (
                                                    <div className="text-danger mt-1">
                                                        {errors.description}
                                                    </div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <div className='row mt-4'>
                                            <div className='col-12 '>
                                                <Button type='submit' size="small" key="4" className='btn btn-primary' block disabled={loading}>
                                                    {loading ? (
                                                        <Spinner
                                                            style={{ width: "0.7rem", height: "0.7rem" }}

                                                            color="light"
                                                        />
                                                    ) : 'Send report'}

                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            </Main >
        </>
    );
}

export default ReportIssueForm;
