import { fabric } from "fabric";


export const removeFabricObjectsByName = (canvas, name) => {
    canvas?.forEachObject(function (obj) {
        if (obj.name == name) {
            canvas.remove(obj);
        }
    });
};

export const reinitialiseFabric = (canvas) => {
    removeFabricObjectsByName(canvas, "tracing");
    removeFabricObjectsByName(canvas, "boundary");
    removeFabricObjectsByName(canvas, "location");
    removeFabricObjectsByName(canvas, "product");
    removeFabricObjectsByName(canvas, "beacon");
    removeFabricObjectsByName(canvas, "amenity");
    removeFabricObjectsByName(canvas, "safety");
    removeFabricObjectsByName(canvas, "vertical");
    removeFabricObjectsByName(canvas, "short_path");
    removeFabricObjectsByName(canvas, "text");
    removeFabricObjectsByName(canvas, "arrow");

};

export const pinNameTextBringtoFront = (canvas) => {
    canvas?.forEachObject(function (obj) {
        if (obj.type === 'group') {
            obj?.getObjects().forEach((rect) => {
                if (rect?.type === 'text' || rect?.type === 'rect') {
                    obj.bringForward(rect)
                    canvas.renderAll();
                }
            })
        }
    })
}

export const canvasBackGroundColor = (canvas, color) => {
    // const rgbColor = color ? hexToRgb(color, 0.4) : '#F6F7F3';
    const rgbColor = color ?? '#F6F7F3';
    canvas.backgroundColor = rgbColor;
    canvas.renderAll();
}

export function getSquareCoordinates(centerX, centerY, sideLength) {
    const halfSide = sideLength / 2;

    const topLeftX = centerX - halfSide;
    const topLeftY = centerY - halfSide;

    const topRightX = centerX + halfSide;
    const topRightY = centerY - halfSide;

    const bottomLeftX = centerX - halfSide;
    const bottomLeftY = centerY + halfSide;

    const bottomRightX = centerX + halfSide;
    const bottomRightY = centerY + halfSide;
    return [
        { x: topLeftX, y: topLeftY },
        { x: topRightX, y: topRightY },
        { x: bottomRightX, y: bottomRightY },
        { x: bottomLeftX, y: bottomLeftY }
    ];
}

export const ChangeSvgColorPassingBE = (svg, color) => {
    const originalSvgString = svg;
    const desiredColor = color;
    const parser = new DOMParser();
    const doc = parser.parseFromString(originalSvgString, "image/svg+xml");
    const svgElement = doc.documentElement;

    const pathElement = svgElement.querySelector("#svg_1");

    pathElement.setAttribute("fill", desiredColor);

    const updatedSvgString = new XMLSerializer().serializeToString(svgElement);

    return updatedSvgString;
};

export function applyZoom(canvas, centerX, centerY, zoom, type,) {
    canvas.selection = false;

    if (zoom > 20) zoom = 20;
    if (zoom < 0.05) zoom = 0.05;

    const currentZoom = canvas.getZoom();
    canvas.forEachObject((obj) => {
        const center = obj.getCenterPoint();
        if (
            obj.name != "short_path" &&
            // obj.name != "arrow"
            // &&
            obj.name != "tracing" &&
            obj.name != "boundary" &&
            obj.name != "text"
        ) {
            if (obj.type == "group" && !obj?.id && obj.name != "text") {

                obj.forEachObject((obj1) => {
                    const center = obj1.getCenterPoint();
                    const initialLeft = obj.initialLeft;
                    const initialTop = obj.initialTop;
                    const initialZoom = obj.initialZoom ?? 1;
                    const currentZoom = canvas.getZoom();
                    obj1.set({
                        ignoreZoom: true,
                        skipAbsolute: true,
                        scaleX: 1 / canvas?.getZoom(),
                        scaleY: 1 / canvas?.getZoom(),
                        strokeWidth: 1
                    });
                });
            }

            /* Text maintain size */
            // if (obj?.type === 'text' && obj?.name === 'text') {
            //   obj.originalFontSize = obj.fontSize;
            // }
            // else if (obj?.type === 'text' && obj?.name === 'text') {
            //   obj.fontSize = obj.originalFontSize * (1 / zoom);
            //   // obj.setCoords();
            // }
            else {
                obj.set({
                    ignoreZoom: true,
                    skipAbsolute: true,
                    scaleX: 1 / canvas?.getZoom(),
                    scaleY: 1 / canvas?.getZoom()
                });
            }
            obj.setPositionByOrigin(center, "center", "center");
        }
        // else if (obj.name == "short_path") {
        //     const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
        //     let strokeWidth = originalStrokeWidth / zoom;
        //     strokeWidth = Math.max(Math.min(strokeWidth, originalStrokeWidth), 0.3);
        //     obj.set({ strokeWidth: strokeWidth });
        // }
        else if (obj.name == "short_path") {
            const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
            const initialZoom = obj.initialZoom || 1; // Fallback to 1 if initial zoom is not set
            const currentZoom = canvas.getZoom();
            const strokeWidth = originalStrokeWidth * (initialZoom / currentZoom);
            obj.set({ strokeWidth: strokeWidth });
            obj.setPositionByOrigin(center, "center", "center");
        }

        /* Adjust opacity of pins in zoom out */
        if (obj?.name != "tracing" &&
            obj?.types != 'highlight_pin' &&
            obj?.name != "short_path" &&
            obj?.name != "text" &&
            obj?.name != "safety" &&
            obj?.name != "amenity" &&
            obj?.name != "vertical" &&
            obj?.name != "boundary" &&
            canvas.getZoom() <= 0.35
        ) {
            if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
                console.log(zoom, '0.6')
                obj.set({ opacity: 0.6 })
            } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
                obj.set({ opacity: 0.3 })
                console.log(zoom, '0.3')
            } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
                obj.set({ opacity: 0.1 })
                console.log(zoom, '0.1')
            } else if (canvas.getZoom() < 0.1) {
                console.log(zoom, '0')
                obj.set({ opacity: 0 })
            }
        } else if (obj?.name != "boundary") {
            obj.set({ opacity: 1 })
        }
        canvas.requestRenderAll();
    });
    if (type == "mouse") {
        canvas.zoomToPoint({ x: centerX, y: centerY }, zoom);
    } else {
        const easingFactor = 0.3;
        const smoothZoom = canvas.getZoom() + (zoom - canvas.getZoom()) * easingFactor;
        canvas.zoomToPoint({ x: centerX, y: centerY }, smoothZoom);
    }
    canvas.requestRenderAll();
    // lastZoom = zoom;
}

export function applyPinchZoom(canvas) {
    console.log('pinchzoom')
    canvas.forEachObject((obj) => {
        const center = obj.getCenterPoint();

        if (
            obj.name != "short_path" &&
            // obj.name != "arrow"
            // &&
            obj.name != "tracing" &&
            obj.name != "boundary" &&
            obj.name != "text"
        ) {
            if (obj.type == "group" && !obj?.id && obj.name != "text") {
                obj.forEachObject((obj1) => {
                    obj1.set({
                        ignoreZoom: true,
                        skipAbsolute: true,
                        scaleX: 1 / canvas?.getZoom(),
                        scaleY: 1 / canvas?.getZoom(),
                        strokeWidth: 1
                    });
                });
            }
            else {
                obj.set({
                    ignoreZoom: true,
                    skipAbsolute: true,
                    scaleX: 1 / canvas?.getZoom(),
                    scaleY: 1 / canvas?.getZoom()
                });
            }
            obj.setPositionByOrigin(center, "center", "center");

            canvas.requestRenderAll();
        }
        // else if (obj.name == "short_path") {
        //     const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
        //     let strokeWidth = originalStrokeWidth / canvas?.getZoom();
        //     strokeWidth = Math.max(Math.min(strokeWidth, originalStrokeWidth), 0.3);
        //     obj.set({ strokeWidth: strokeWidth });
        // }
        else if (obj.name == "short_path") {
            const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
            const initialZoom = obj.initialZoom || 1; // Fallback to 1 if initial zoom is not set
            const currentZoom = canvas.getZoom();
            const strokeWidth = originalStrokeWidth * (initialZoom / currentZoom);
            obj.set({
                strokeWidth: strokeWidth,
                ignoreZoom: true,
                skipAbsolute: true,
            });
            obj.setPositionByOrigin(center, "center", "center");

        }

        /* Adjust opacity of pins in zoom out */
        if (obj?.name != "tracing" &&
            obj?.types != 'highlight_pin' &&
            obj?.name != "short_path" &&
            obj?.name != "text" &&
            obj?.name != "safety" &&
            obj?.name != "amenity" &&
            obj?.name != "vertical" &&
            obj?.name != "boundary" &&
            canvas.getZoom() <= 0.35
        ) {

            if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
                obj.set({ opacity: 0.6 })
            } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
                obj.set({ opacity: 0.3 })
            } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
                obj.set({ opacity: 0.1 })
            } else if (canvas.getZoom() < 0.1) {
                obj.set({ opacity: 0 })
            }
            // if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
            //     console.log(zoom, '0.5')
            //     obj.set({ opacity: 0.5 })
            // } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
            //     obj.set({ opacity: 0.2 })
            //     console.log(zoom, '0.2')
            // } else if (canvas.getZoom() < 0.2) {
            //     console.log(zoom, '0')
            //     obj.set({ opacity: 0 })
            // }


        } else if (obj?.name != "boundary") {
            obj.set({ opacity: 1 })
        }

        canvas.requestRenderAll();

    });

}

// export const rotateCanvas = (degrees, canvas) => {
//     let canvasCenter = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
//     let radians = fabric.util.degreesToRadians(degrees);

//     canvas.forEachObject(function (obj) {
//         if (obj.name === "tracing") {
//             // Rotate the tracing object and adjust its position
//             let objectOrigin = new fabric.Point(obj.left, obj.top);
//             let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians);

//             obj.set({
//                 left: new_loc.x,
//                 top: new_loc.y,
//                 angle: obj.angle + degrees
//             });
//         } else {
//             // Keep other objects at the same position but adjust for canvas rotation
//             let objectOrigin = new fabric.Point(obj.left, obj.top);
//             let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians);

//             obj.set({
//                 left: new_loc.x,
//                 top: new_loc.y,
//                 angle: obj.angle + degrees
//             });
//             if (!["short_path", "arrow", "text", "boundary"].includes(obj.name)) {
//                 obj.rotate(0)
//             }
//         }

//         obj.setCoords();
//     });

//     /* Background image rotate test */
//     if (canvas.backgroundImage) {
//         let bg = canvas.backgroundImage;
//         let bgOrigin = new fabric.Point(bg.left, bg.top);
//         let newBgLoc = fabric.util.rotatePoint(bgOrigin, canvasCenter, radians);
//         bg.set({
//             left: newBgLoc.x,
//             top: newBgLoc.y,
//             angle: (bg.angle || 0) + degrees,
//             originX: 'center',
//             originY: 'center'
//         });
//         bg.setCoords(); 
//     }

//     canvas.requestRenderAll();
// };

export const rotateCanvas = (degrees, canvas, fixedPoint) => {
    let radians = fabric.util.degreesToRadians(degrees);

    canvas.forEachObject(function (obj) {
        if (obj.name === "tracing") {
            // Rotate the tracing object around the fixed point
            let objectOrigin = new fabric.Point(obj.left, obj.top);
            let new_loc = fabric.util.rotatePoint(objectOrigin, fixedPoint, radians);

            obj.set({
                left: new_loc.x,
                top: new_loc.y,
                angle: obj.angle + degrees
            });
        } else {
            // Rotate other objects around the fixed point
            let objectOrigin = new fabric.Point(obj.left, obj.top);
            let new_loc = fabric.util.rotatePoint(objectOrigin, fixedPoint, radians);

            obj.set({
                left: new_loc.x,
                top: new_loc.y,
                angle: obj.angle + degrees
            });
            if (!["short_path", "arrow", "text", "boundary"].includes(obj.name)) {
                obj.rotate(0);
            }
        }

        obj.setCoords();
    });

    /* Background image rotate around the fixed point */
    if (canvas.backgroundImage) {
        let bg = canvas.backgroundImage;
        let bgOrigin = new fabric.Point(bg.left, bg.top);
        let newBgLoc = fabric.util.rotatePoint(bgOrigin, fixedPoint, radians);
        bg.set({
            left: newBgLoc.x,
            top: newBgLoc.y,
            angle: (bg.angle || 0) + degrees,
            originX: 'center',
            originY: 'center'
        });
        bg.setCoords();
    }
    // alert(JSON.stringify(fixedPoint))
    // rotateDragToPoint(canvas, fixedPoint)
    canvas.requestRenderAll();
};


export const addApointToRotate = (canvas, midpoint) => {
    let centerPoint;
    const transform = canvas.viewportTransform;
    if (midpoint) {
      centerPoint = midpoint
    } else if (transform) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const topLeft = fabric.util.transformPoint({ x: 0, y: 0 }, fabric.util.invertTransform(transform));
      const bottomRight = fabric.util.transformPoint({ x: viewportWidth, y: viewportHeight }, fabric.util.invertTransform(transform));

      const visibleArea = {
        x1: topLeft.x,
        y1: topLeft.y,
        x2: bottomRight.x,
        y2: bottomRight.y,
      };
      const xCenter = (visibleArea.x1 + visibleArea.x2) / 2;
      const yCenter = (visibleArea.y1 + visibleArea.y2) / 2;
      centerPoint = {
        x: xCenter,
        y: yCenter
      }

    }
    const circle = new fabric.Circle({
      left: centerPoint.x,
      top: centerPoint.y,
      radius: 5,
      fill: "rgba(0,0,0,0)",
      // fill: "red",
      originX: "center",
      originY: "center",
      selectable: false,
      types: "centerPointPin",
      name: "centerPointPin"
    });
    // Remove existing visible area rectangles before adding a new one
    const existingCircle = canvas.getObjects('circle').filter(obj => obj.fill === 'rgba(0,0,0,0)');
    existingCircle.forEach(cir => canvas.remove(cir));

    canvas.add(circle);
    canvas.renderAll();
}

export const rotateDragToPoint = (canvas, point) => {
    // canvas.setZoom(1); // reset zoom so pan actions work as expected
    let vpw = canvas.width / 1;
    let vph = canvas.height / 1;
    let x = point.x - vpw / 2; // x is the location where the top left of the viewport should be
    let y = point.y - vph / 2; // y idem
    canvas.absolutePan({ x, y });
    // canvas.setZoom(1);
};

export const dragToPoint = (canvas, point) => {
    canvas.setZoom(1); // reset zoom so pan actions work as expected
    let vpw = canvas.width / 1;
    let vph = canvas.height / 1;
    let x = point.x - vpw / 2; // x is the location where the top left of the viewport should be
    let y = point.y - vph / 2; // y idem
    canvas.absolutePan({ x, y });
    canvas.setZoom(1);
};


export const getAngleBetweenTouches = (touches) => {
    let [touch1, touch2] = touches;
    let dx = touch2.pageX - touch1.pageX;
    let dy = touch2.pageY - touch1.pageY;
    return Math.atan2(dy, dx) * (180 / Math.PI);
};

export const canvasBackGroundImage = (canvas, image) => {
    fabric.Image.fromURL(image, function (img) {
        // Get the width and height of the canvas
        const canvasWidth = canvas.getWidth();
        const canvasHeight = canvas.getHeight();

        const left = (canvasWidth - img.width) / 2;
        const top = (canvasHeight - img.height) / 2;
        img.scale(0.85);
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            left: 956 / 2,
            top: 641 / 2,
            originX: 'center',
            originY: 'center'
        });
    });
}

